import { graphql, PageProps } from 'gatsby';
import React from 'react';

import { Box } from '../../components/Core';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import ThematiquesSection from '../../components/ThematiquesSection/ThematiquesSection';

type IThematiquesTemplate = PageProps<
  Queries.PageThematiquesQuery,
  IPageContext
>;

const ThematiquesPageTemplate: FCWithClassName<IThematiquesTemplate> = (
  props
) => {
  const { data, pageContext } = props;
  const pageData = data.wpPage;
  const pageTemplateData = 'contenuThematiques' in data.wpPage.template ? data.wpPage.template.contenuThematiques : null;
  const thematiques = data.allWpThematique.nodes;
  if (!pageData || !pageContext.defaultLanguage || !pageTemplateData) return null;

  const ctaLabel = {
    EN: 'See more',
    FR: 'En voir +',
  };
  return (
    <Layout
      currentLanguage={pageContext.currentLanguage}
      currentPath={pageData.link}
      menuData={pageContext.menus}
      siteSettings={pageContext.siteSettings}
      submenuItems={pageContext.submenuItems}
      translations={pageData.translations}
    >
      <SEO pageData={pageData} />
      <Box className="mt-gap">
        <ThematiquesSection
          ctaLabel={ctaLabel[pageContext.currentLanguage.code as 'FR' | 'EN']}
          thematiques={thematiques}
          description={
            pageTemplateData.sectionThematiques?.description
          }
          title={pageTemplateData.sectionThematiques?.titre}
        />
      </Box>
    </Layout>
  );
};

export default ThematiquesPageTemplate;

export const query = graphql`
  query PageThematiques($id: String, $currentLanguageSlug: String) {
    wpPage(id: { eq: $id }) {
      featuredImage {
        node {
          ...MediaItemFragment
          localFile {
            ...HeroImage
          }
        }
      }
      language {
        locale
        slug
      }
      link
      seo {
        ...SEOFragment
      }
      slug
      title
      translations {
        language {
          code
          locale
        }
        uri
      }
      template {
        ... on WpTemplate_PageThematiques {
          contenuThematiques {
            sectionThematiques {
              description
              titre
            }
          }
        }
      }
    }
    allWpThematique(
      filter: { language: { slug: { eq: $currentLanguageSlug } } }
      sort: {fields: informations___displayOrder, order: ASC}
    ) {
      nodes {
        ...ThematiqueDisplayData
      }
    }
  }
`;
